import { markupInContent } from '../../../utils/markup';

export const Letters = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

/**
 * @typedef {
 *   'clap-back' |  'cloze' |  'credential' |  'draw' |  'jumble' |  'long-answer' |
 *   'matching-cards' |  'matching-lines' |  'multiple-choice' |  'piano' |  'pin' |
 *   'puzzle' |  'order' |  'short-answer' |  'word-search' | 'math' | 'jumble'
 * } QuestionFormats
 */
export const QuestionFormats = {
  CLAP_BACK: 'clap-back',
  CLOZE: 'cloze',
  CREDENTIAL: 'credential',
  DRAW: 'draw',
  JUMBLE: 'jumble',
  LONG_ANSWER: 'long-answer',
  MATCHING_CARDS: 'matching-cards',
  MATCHING_LINES: 'matching-lines',
  MULTIPLE_CHOICE: 'multiple-choice',
  PIANO: 'piano',
  PIN: 'pin',
  PUZZLE: 'puzzle',
  ORDER: 'order',
  SHORT_ANSWER: 'short-answer',
  WORD_SEARCH: 'word-search',
  MATH: 'math',
  JUMBLE: 'jumble',
};

/**
 * @typedef {'essay' | 'text' | 'video' | 'audio' | 'image' | 'match' | 'order' | 'math' | 'jumble'} QuestionTypes
 */
const QuestionTypes = {
  ESSAY: 'essay',
  TEXT: 'text',
  VIDEO: 'video',
  AUDIO: 'audio',
  IMAGE: 'image',
  MATCH: 'match',
  ORDER: 'order',
  MATH: 'math',
  JUMBLE: 'jumble',
};

/**
 * @typedef {{
 *  text: string;
 *  isCorrect: boolean;
 * }} Answer
 *
 * @typedef {{
 *  text: string;
 * }} MatchField
 *
 * @typedef {{
 *  leftItem: number;
 *  rightItem: number;
 * }} MatchAnswers
 *
 * @typedef {{
 *  id: number;
 *  text: string;
 * }} OrderItems
 * 
 * @typedef {{
 *  id: number;
 *  value: string;
 * }} MathItems
 *
 * @typedef {{
 *  id?: string;
 *  question: string;
 *  type: QuestionTypes;
 *  text?: string;
 *  questionNumber: string;
 *  difficulty?: number;
 *  layoutOrientation?: 'vert' | 'horiz';
 *  answers?: Answer[];
 *  shortAnswers?: string[];
 *  matchLeftItems?: MatchField[];
 *  matchRightItems?: MatchField[];
 *  matchAnswers?: MatchAnswers[];
 *  orderItems?: OrderItems[];
 *  orderAnswers?: Number[];
 *  mathItems?: MathItems[];
 *  mathAnswer?: string;
 *  mathNumAnswerItems: number;
 *  jumbleLetters?: string;
 *  jumbleAnswer?: string;
 * 
 * }} BackEndQuestion
 *
 * @typedef {{
 *  id?: string;
 *  question: string;
 *  type: QuestionTypes;
 *  content?: string;
 *  questionNumber: number;
 *  difficulty?: string;
 *  layoutOrientation?: 'vert' | 'horiz';
 *  answers?: Answer[];
 *  shortAnswers?: string[];
 *  questionFormat: QuestionFormats;
 *  matchLeftItems?: MatchField[];
 *  matchRightItems?: MatchField[];
 *  matchAnswers?: MatchAnswers[];
 *  orderItems?: OrderItems[];
 *  orderAnswers?: Number[];
 *  mathItems?: MathItems[];
 *  mathAnswer?: string;
 *  mathNumAnswerItems: number;
 *  jumbleLetters?: string;
 *  jumbleAnswer?: string;
 * }} FrontEndQuestion
 */

/**
 * @param {FrontEndQuestion} question
 * @returns {BackEndQuestion} the payload
 */
export function prepareQuestion(question) {
  /**
   * @type {BackEndQuestion}
   */
  const payload = {
    question: question.question,
    type: getQuestionType(question),
    text: question.content || null,
    questionNumber: parseInt(question.questionNumber),
    difficulty: parseInt(question.difficulty) || null,
    layoutOrientation: question.layoutOrientation,
  };

  if (question.questionFormat === QuestionFormats.SHORT_ANSWER) {
    return {
      ...payload,
      shortAnswers: question.shortAnswers || [],
    };
  }

  if (question.questionFormat === QuestionFormats.MULTIPLE_CHOICE) {
    return {
      ...payload,
      answers: question.answers || [],
    };
  }

  if (question.questionFormat === QuestionFormats.MATCHING_LINES) {
    return {
      ...payload,
      matchLeftItems: question.matchLeftItems || [],
      matchRightItems: question.matchRightItems || [],
      matchAnswers: question.matchAnswers || [],
    };
  }

  if (question.questionFormat === QuestionFormats.ORDER) {
    return {
      ...payload,
      orderItems: question.orderItems || [],
      orderAnswers: question.orderAnswers || [],
    };
  }

  if (question.questionFormat === QuestionFormats.MATH) {
    return {
      ...payload,
      mathItems: question.mathItems || [],
      mathAnswer: question.mathAnswer || "0",
      mathNumAnswerItems: question.mathNumAnswerItems || 0,
    };
  }

  if (question.questionFormat === QuestionFormats.JUMBLE) {
    return {
      ...payload,
      jumbleLetters: question.jumbleLetters || "",
      jumbleAnswer: question.jumbleAnswer || "",
    };
  }

  return payload;
}

/**
 * Converts the question data returned by the back-end
 * in the format expected by the front-end.
 * @param {BackEndQuestion} data
 * @returns {FrontEndQuestion}
 */
export function getQuestionFromResponse(data = {}) {
  /**
   * @type {FrontEndQuestion}
   */
  const question = {
    id: data.id,
    question: data.question,
    type: data.type,
    content: data.text,
    questionNumber: `${data.questionNumber}`,
    difficulty: `${data.difficulty}`,
    layoutOrientation: data.layoutOrientation,
    ...getTypeSpecificAttributesFromResponse(data),
  };

  return question;
}

function getTypeSpecificAttributesFromResponse(data) {
  /**
   * @type {{
   *  questionFormat: QuestionFormats;
   *  answers?: Answer[];
   *  shortAnswers?: string[];
   *  matchLeftItems?: MatchField[];
   *  matchRightItems?: MatchField[];
   *  matchAnswers?: MatchAnswers[];
   *  orderItems?: OrderItems[];
   *  orderAnswers?: Number[];
   *  mathItems?: MathItems[];
   *  mathAnswer?: string;
   *  mathNumAnswerItems: number;
   *  jumbleLetters?: string;
   *  jumbleAnswer?: string;
   * }}
   */
  const attributes = {};

  const questionFormat = getQuestionFormat(data);
  attributes.questionFormat = questionFormat;

  if (questionFormat === QuestionFormats.MULTIPLE_CHOICE) {
    attributes.answers = data.answers || [];
  } else if (questionFormat === QuestionFormats.SHORT_ANSWER) {
    attributes.shortAnswers = data.shortAnswers || [];
  } else if (questionFormat === QuestionFormats.MATCHING_LINES) {
    attributes.matchLeftItems = data.matchLeftItems || [];
    attributes.matchRightItems = data.matchRightItems || [];
    attributes.matchAnswers = data.matchAnswers || [];
  } else if (questionFormat === QuestionFormats.ORDER) {
    attributes.orderItems = data.orderItems || [];
    attributes.orderAnswers = data.orderAnswers || [];
  } else if (questionFormat === QuestionFormats.MATH) {
    attributes.mathItems = data.mathItems || [];
    attributes.mathAnswer = data.mathAnswer || "0";
    attributes.mathNumAnswerItems = data.mathNumAnswerItems || 0;
  } else if (questionFormat === QuestionFormats.JUMBLE) {
    attributes.jumbleLetters = data.jumbleLetters || "";
    attributes.jumbleAnswer = data.jumbleAnswer || "";
  }

  return attributes;
}

/**
 * @param {BackEndQuestion} data
 * @returns {QuestionFormats} the format type
 */
function getQuestionFormat(data) {
  if (data.type === QuestionTypes.ESSAY) {
    return QuestionFormats.LONG_ANSWER;
  }
  if (data.type === QuestionTypes.MATCH) {
    if (data.matchLeftItems && data.matchRightItems && data.matchAnswers) {
      return QuestionFormats.MATCHING_LINES;
    }
  }
  if (data.type === QuestionTypes.ORDER) {
    if (data.orderItems && data.orderAnswers) {
      return QuestionFormats.ORDER;
    }
  }
  if (data.type === QuestionTypes.MATH) {
    if (data.mathItems && data.mathAnswer && data.mathNumAnswerItems) {
      return QuestionFormats.MATH;
    }
  }
  if (data.type === QuestionTypes.JUMBLE) {
    if (data.jumbleLetters && data.jumbleAnswer) {
      return QuestionFormats.JUMBLE;
    }
  }
  if (!data.answers && data.shortAnswers) {
    return QuestionFormats.SHORT_ANSWER;
  }

  return QuestionFormats.MULTIPLE_CHOICE;
}

/**
 * @param {FrontEndQuestion} question
 * @returns {QuestionTypes} The question type in the back-end
 */
function getQuestionType(question) {
  switch (question.questionFormat) {
    case QuestionFormats.LONG_ANSWER:
      return QuestionTypes.ESSAY;
    case QuestionFormats.MATCHING_LINES:
      return QuestionTypes.MATCH;
    case QuestionFormats.ORDER:
      return QuestionTypes.ORDER;
    case QuestionFormats.MATH:
      return QuestionTypes.MATH;
    case QuestionFormats.MULTIPLE_CHOICE:
      return QuestionTypes.TEXT;
    case QuestionFormats.JUMBLE:
      return QuestionTypes.JUMBLE;

    default:
      return QuestionTypes.TEXT;
  }
}
