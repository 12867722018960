import styled from 'styled-components/macro';
import MUIIconButton from '@material-ui/core/IconButton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  align-items: center;
  position: fixed;
  height: calc(100vh - ${({ theme }) => theme.headerHeight});
  top: ${({ theme }) => theme.headerHeight};
  left: 0;
  z-index:1;
`;

export const FullScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  align-items: center;
  position: fixed;
  top: calc(${({ theme }) => theme.headerHeight} - 20px);
  left: 0;
  z-index:1;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction:row;
  width:100%;
  padding: 0 5%;
  box-sizing: border-box;

  

`;
export const TabsContainer = styled.div`
  display: flex;
  flex-direction:row;
  width:100%;
  padding: 0 5%;
  box-sizing: border-box;


`;

export const Title = styled.span`
  font-size: 24px;
  color: #424242;
  margin: 30px 0;
`;

export const IconButton = styled(MUIIconButton)`
  && {
    display: flex;
    align-self: center;

    .MuiSvgIcon-root {
      font-size: 35px;
    }

    color: #424242;
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  display: flex;
  align-self: flex-end;
  bottom: 100px;
  right: 5%;
`;
