import React, { useState } from 'react';
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Flex } from 'rebass';
import { InputGroup, Label, QuestionField } from './styled';
import { SUPPORTED_MEDIA_TYPES } from '.';
import ContentItem from './ContentItem';
import AddContentItemButton from './AddContentItemButton';

const QuestionLayout = ({ updateContent, removeContent, getContent, contentDictionary, jumbleLetters, setJumbleLetters, jumbleAnswer, setJumbleAnswer }) => {
  const initialContentItems = useMemo(() => {
    const contentItems = [];
    const contentKeys = Object.keys(contentDictionary);
    contentKeys.forEach(key => {
        if(SUPPORTED_MEDIA_TYPES.includes(key)) {
            const id = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
            contentItems.push({ id, type: key });
        }
    });
    return contentItems;
  }, [contentDictionary]);

  const [contentItems, setContentItems] = useState(initialContentItems);

  const handleJumbleLettersChange = (e) => {
    setJumbleLetters(e.target.value);
  }

  const handleJumbleAnswerChange = (e) => {
    setJumbleAnswer(e.target.value);
  }

  return (
    <Flex flexDirection="column" justifyContent="space-between" mx="auto" maxWidth="700px" >
    { 
        contentItems.length > 0 && contentItems.map((item) => {
          const content = getContent(item.type);
          return (
            <ContentItem 
                key={item.id}
                itemId={item.id}
                type={item.type}
                setContentItems={setContentItems}
                content={content}
                updateContent={updateContent}
                removeContent={removeContent}
            />
          )
        })
      }
      <AddContentItemButton 
        contentItems={contentItems} 
        setContentItems={setContentItems} 
        updateContent={updateContent}
      />
      <Flex flexDirection='column' justifyContent='space-between' alignItems="center">
        <Box width='100%' my={5}>
          <InputGroup>
              <Label alignSelf="center">Letters</Label>
              <QuestionField
                  placeholder="Type jumbled letters here."
                  value={jumbleLetters}
                  onChange={handleJumbleLettersChange}
              />
            </InputGroup>
        </Box>
        <Box width="100%">
          <InputGroup>
              <Label alignSelf="center">Answer</Label>
              <QuestionField
                  placeholder="Type the correct order of the letters above here."
                  value={jumbleAnswer}
                  onChange={handleJumbleAnswerChange}
              />
            </InputGroup>
        </Box>
      </Flex>
    </Flex>
  )
}


QuestionLayout.propTypes = {
  updateContent: PropTypes.func.isRequired,
  removeContent: PropTypes.func.isRequired,
  getContent: PropTypes.func.isRequired,
}

export default QuestionLayout