import React, { useMemo, useState } from 'react';
import { Flex } from 'rebass';
import { types as mediaTypes } from '../../../MediaLibrary/Media';
import { AddButton } from '../../../UI/Button';
import { Menu, MenuItem } from '@material-ui/core';
import MediaLibrarySelector from '../../../MediaLibrary/Selector';
import { assetContentTransformer } from '../../../../utils/mediaLibrary';
import { capitalize } from 'lodash';
import { SUPPORTED_MEDIA_TYPES } from '.';

const AddContentItemButton = ({ contentItems, setContentItems, updateContent }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [mediaPicker, setMediaPicker] = useState({ answerIndex: null, showTypes: [], show: false });

    const handleContextMenu = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const canAddContentItem = useMemo(() => {
        // we can add a content item if at least one of the following is true:
        // 1. there are no content items
        // 2. the content type hasn't been used yet

        if (contentItems.length === 0) {
            return true;
        }

        const usedContentTypes = contentItems.map(item => item.type);
        const unusedContentTypes = SUPPORTED_MEDIA_TYPES.filter(type => !usedContentTypes.includes(type));

        return unusedContentTypes.length > 1;
    }, [contentItems]);

    const addContentItem = (type) => {
        const newItem = { id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER), type };
        setContentItems(prevItems => [...prevItems, newItem]);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const closeMediaPicker = () => {
        setMediaPicker({...mediaPicker, show: false});
    };

    const openMediaPickerDialog = (answerIndex = null, showTypes = []) => {
        if (showTypes.length === 0) {
          showTypes = [mediaTypes.IMAGE, mediaTypes.VIDEO]
        }
        
        setMediaPicker({ ...mediaPicker, answerIndex, showTypes, show: true });
    }

    const insertAsset = (asset) => {
        const transformedAsset = assetContentTransformer(asset);
        if (mediaPicker.answerIndex === null) {
            updateContent(transformedAsset);
        } 
        addContentItem(asset.type);
        closeMediaPicker()
    }

    const handleAddContentItem = (type) => {
        const showTypes = [];
        
        if (type === mediaTypes.AUDIO) {
            showTypes.push(mediaTypes.AUDIO);
        } else if (type === mediaTypes.VIDEO) {
            showTypes.push(mediaTypes.VIDEO);
        } else if (type === mediaTypes.IMAGE) {
            showTypes.push(mediaTypes.IMAGE);
        }

        if (type === mediaTypes.PHRASE) {
            addContentItem(type);
        } else {
            openMediaPickerDialog(null, showTypes);
        }

        handleClose();
    };

    const unusedContentTypes = useMemo(() => {
        const usedContentTypes = contentItems.map(item => item.type);
        return SUPPORTED_MEDIA_TYPES.filter(type => !usedContentTypes.includes(type));
    }, [contentItems]);

    return (
        <Flex flexDirection="row" justifyContent="center" alignItems="center" py={3}>
            {canAddContentItem && <AddButton
                aria-controls="content-menu" 
                aria-haspopup="true"
                onClick={handleContextMenu}
                fullWidth
                center
            >
                Add Content
            </AddButton>}
            <Menu
                id="content-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {unusedContentTypes.map((type) => (
                    <MenuItem key={type} onClick={() => handleAddContentItem(type)}>
                        {capitalize(type)}
                    </MenuItem>
                ))}
            </Menu>
            {
            mediaPicker.show && (
                <MediaLibrarySelector
                    onSelectMedia={(a) => insertAsset(a)}
                    onClose={closeMediaPicker}
                    showTypes={mediaPicker.showTypes}
                    fullscreen={true}
                />
            )}
        </Flex>
    )
}

export default AddContentItemButton;