import ClapBackIcon from '../../../../assets/images/noun-clap.svg';
import ClozeQuestionIcon from '../../../../assets/images/cloze-question.svg';
import DrawQuestionIcon from '../../../../assets/images/noun-draw.svg';
import JumbleIcon from '../../../../assets/images/jumble-question.svg';
import LongAnswerIcon from '../../../../assets/images/noun-long-read.svg';
import MatchingCardsIcon from '../../../../assets/images/matching-cards.svg';
import MatchingLinesIcon from '../../../../assets/images/matching-lines.svg';
import MultipleChoiceIcon from '../../../../assets/images/noun-multiple-choice.svg';
import PersonIcon from '../../../../assets/images/person-24-px.svg';
import PianoIcon from '../../../../assets/images/noun-piano.svg';
import PinQuestionIcon from '../../../../assets/images/pin-question.svg';
import PuzzleIcon from '../../../../assets/images/noun-puzzle.svg';
import { QuestionFormats } from '../../../../contexts/api/question/common';
import OrderCardsIcon from '../../../../assets/images/reorder-cards.svg';
import ShortAnswerIcon from '../../../../assets/images/noun-short-text.svg';
import WordSearchIcon from '../../../../assets/images/word-search.svg';
import MathCalculatorIcon from '../../../../assets/images/math-calculator.svg';

export const sidebarDroppableId = 'drag-items-list';
export const droppableId = 'dragged-items-list';

export const questionIcons = {
  credential: PersonIcon,
  'multiple-choice': MultipleChoiceIcon,
  'short-answer': ShortAnswerIcon,
  'long-answer': LongAnswerIcon,
  cloze: ClozeQuestionIcon,
  'word-search': WordSearchIcon,
  'matching-cards': MatchingCardsIcon,
  'matching-lines': MatchingLinesIcon,
  pin: PinQuestionIcon,
  draw: DrawQuestionIcon,
  order: OrderCardsIcon,
  puzzle: PuzzleIcon,
  jumble: JumbleIcon,
  'clap-back': ClapBackIcon,
  piano: PianoIcon,
  math: MathCalculatorIcon,
};

export const questionTypes = [
  // {
  //   id: 'Credential',
  //   content: 'Credential',
  //   questionFormat: QuestionFormats.CREDENTIAL,
  // },
  {
    id: 'Multiple Choice',
    content: 'Multiple Choice',
    questionFormat: QuestionFormats.MULTIPLE_CHOICE,
  },
  // {
  //   id: 'Short Answer',
  //   content: 'Short Answer',
  //   questionFormat: QuestionFormats.SHORT_ANSWER,
  // },
  {
    id: 'Long Answer',
    content: 'Long Answer',
    questionFormat: QuestionFormats.LONG_ANSWER,
  },
  // {
  //   id: 'Cloze Question',
  //   content: 'Cloze Question',
  //   questionFormat: QuestionFormats.CLOZE,
  // },
  // {
  //   id: 'Word Search',
  //   content: 'Word Search',
  //   questionFormat: QuestionFormats.WORD_SEARCH,
  // },
  {
    id: 'Matching Items',
    content: 'Matching Items',
    questionFormat: QuestionFormats.MATCHING_LINES,
  },
  // {
  //   id: 'Pin Question',
  //   content: 'Pin Question',
  //   questionFormat: QuestionFormats.PIN,
  // },
  // {
  //   id: 'Draw Question',
  //   content: 'Draw Question',
  //   questionFormat: QuestionFormats.DRAW,
  // },
  // {
  //   id: 'Matching Cards',
  //   content: 'Matching Cards',
  //   questionFormat: QuestionFormats.MATCHING_CARDS,
  // },
  {
    id: 'Order Items',
    content: 'Order Items',
    questionFormat: QuestionFormats.ORDER,
  },
  {
    id: 'Math',
    content: 'Math',
    questionFormat: QuestionFormats.MATH,
  },
  // { id: 'Puzzle', content: 'Puzzle', questionFormat: QuestionFormats.PUZZLE },
  { id: 'Jumble', content: 'Jumble', questionFormat: QuestionFormats.JUMBLE },
  // {
  //   id: 'Clap Back',
  //   content: 'Clap Back',
  //   questionFormat: QuestionFormats.CLAP_BACK,
  // },
  // {
  //   id: 'Piano Question',
  //   content: 'Piano Question',
  //   questionFormat: QuestionFormats.PIANO,
  // },
];
