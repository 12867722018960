import Layout from '../../../Layout';
import React, { useEffect, useMemo, useState } from 'react';
import { CancelButton, SaveButton, Button } from '../../../UI/Button';
import { Link } from '../../../UI';
import { Flex, Box } from 'rebass';
import PropTypes from 'prop-types';
import { types as mediaTypes } from '../../../MediaLibrary/Media';
import {
  Container,
  TopContainer,
  Title,
  ContentContainer,
  ExtraOptionsSidebar,
  ExtraOptions,
  LabelLeft,
  Select,
  Radio,
  Checkbox,
  Label,
  Choices,
  InputGroup,
  QuestionField,
} from './styled';
import QuestionLayout from './QuestionHorizontalLayout';
import QuestionVerticalLayout from './QuestionVerticalLayout';
import { DeleteButton, DeleteButtonContainer } from '../../Navbar/styled';
import { FaTrash } from 'react-icons/fa';
import { QUESTION_CONTENT_TYPES } from '../../../../constants/application';
import ClassedUpMarkupGuide from '../../../../assets/pdf/ClassedUpMarkupGuide.pdf'
import { markupInContent, markupsInContent } from '../../../../utils/markup';


/**
 * @typedef {import('../../../../contexts/api/question/common').Answer} Answer;
 */

/**
 * @type {Answer[]}
 */
const defaultAnswers = () => [
  { text: '', isCorrect: false },
  { text: '', isCorrect: false },
  { text: '', isCorrect: false },
  { text: '', isCorrect: false },
];

const AnswerIndicator = {
  A: 0,
  B: 1,
  C: 2,
  D: 3,
};

const maxNumberOfAnswers = 4;

export const SUPPORTED_MEDIA_TYPES = [mediaTypes.IMAGE, mediaTypes.VIDEO, mediaTypes.AUDIO, mediaTypes.PHRASE];

const TestLibraryQuestionsMultipleChoice = ({
  answers: initialAnswers,
  content: initialContent = '',
  difficulties = [],
  difficulty: initialDifficulty = '',
  layoutOrientation: initialLayoutOrientation = 'vert',
  loading = false,
  onCancel = () => { },
  onDelete = () => { },
  onSave = () => { },
  questionNumber,
  question: initialQuestion = '',
  showButton = false,
  type = QUESTION_CONTENT_TYPES.text,
  onNext,
  onPrevious
}) => {
  const [question, setQuestion] = useState(initialQuestion);
  useEffect(() => {
    setQuestion(initialQuestion);
  }, [initialQuestion]);

  /**
   * parse content string, if any markup exists, store it as a key value pair.
   * initialContent = \phrase(Le Chèvre)\audio(audio-uuid)\image(image-uuid) Hello World
   * dictionary = { phrase: 'Le Chèvre', audio: 'audio-uuid', image: 'image-uuid', text: 'Hello World' }
   */
  const initialContentDictionary = useMemo(() => {
    const dictionary = markupsInContent(initialContent,["image","phrase","video","audio"])   
    return dictionary;
  }, [initialContent]);

  const [contentDictionary, setContentDictionary] = useState(initialContentDictionary);

  const [difficulty, setQuestionDifficulty] = useState(initialDifficulty);
  useEffect(() => {
    setQuestionDifficulty(initialDifficulty);
  }, [initialDifficulty]);

  const [layoutOrientation, setLayoutOrientation] = useState(
    initialLayoutOrientation,
  );
  useEffect(() => {
    setLayoutOrientation(initialLayoutOrientation);
  }, [initialLayoutOrientation]);

  const safeInitialAnswers = useMemo(
    () => getSafeAnswers(initialAnswers, defaultAnswers(), maxNumberOfAnswers),
    [initialAnswers],
  );

  const [answers, setAnswers] = useState(safeInitialAnswers);
  useEffect(() => {
    setAnswers(safeInitialAnswers);
  }, [safeInitialAnswers]);

  const initialAllowMultipleAnswers = useMemo(
    () => hasMoreThanOneCorrectAnswer(safeInitialAnswers),
    [safeInitialAnswers],
  );
  const [allowMultipleAnswers, setAllowMultipleAnswers] = useState(
    initialAllowMultipleAnswers,
  );

  useEffect(() => {
    setAllowMultipleAnswers(initialAllowMultipleAnswers);
  }, [initialAllowMultipleAnswers]);

  useEffect(() => {
    setAnswers((currentAnswers) => {
      if (countCorrectAnswers(currentAnswers) > 1 && !allowMultipleAnswers) {
        // set all to false
        return currentAnswers.map((answer) => ({
          ...answer,
          isCorrect: false,
        }));
      }

      // no change.
      return currentAnswers;
    });
  }, [allowMultipleAnswers]);

  const updateContent = (newContent) => {
    // Update the content in the dictionary if key exists, otherwise add it
    // The new content could be a markup or plain text or a combination of both
    if(newContent) {
      const markups = markupsInContent(newContent,["image","phrase","audio","video"]);
      setContentDictionary((current) => ({        
        ...current,
        ...markups
      }));
    }
  };

  const removeContent = (type) => {
    if(type) {
      const key = type.toLowerCase();
      // Remove the content from the dictionary if key exists
      if(contentDictionary[key]) {
        setContentDictionary((current) => {
          const newValues = {...current};
          delete newValues[key];
          return newValues;
        });
      }
    }
  };

  const getContent = (type) => {
    if(type) {
      const key = type.toLowerCase();
      // Get the content from the dictionary if key exists
      if(contentDictionary[key]) {
        return contentDictionary[key];
      }
    }
    return '';
  }

  const handleChangeAnswerText = (index, text) => {
    setAnswers((current) => {
      const newValues = [...current];
      newValues[index].text = text;
      return newValues;
    });
  };

  const handleChangeMultipleCorrectAnswers = (index, isCorrect) => {
    setAnswers((current) => {
      const newValues = [...current];
      newValues[index].isCorrect = isCorrect;
      return newValues;
    });
  };

  const handleChangeSingleCorrectAnswer = (index, isCorrect) => {
    setAnswers((current) => {
      const newValues = current.map((answer) => ({
        ...answer,
        isCorrect: false,
      }));
      newValues[index].isCorrect = isCorrect;
      return newValues;
    });
  };

  const handleChangeAnswerIsCorrect = allowMultipleAnswers
    ? handleChangeMultipleCorrectAnswers
    : handleChangeSingleCorrectAnswer;

  const handleChangeLayoutOrientation = (event) => {
    setLayoutOrientation(event.target.value);
  };

  const handleChangeDifficulty = (e) => {
    setQuestionDifficulty(e.target.value);
  };

  const dictionaryToContent = (dictionary) => {
    return Object.keys(dictionary).map((key) => {
      // If the key is text, return the content as is
      if (key === 'text') return dictionary[key];
      return `\\${key}(${dictionary[key]})`;
    }).join('');
  };

  const handleSave = () => {
    const content = dictionaryToContent(contentDictionary);
    const payload = {
      question,
      content,
      questionNumber,
      difficulty,
      answers,
      layoutOrientation,
    };

    onSave(payload);
  };

  return (
    <Layout noBodyPadding>
      <Container>
        <TopContainer>
          <Title> Multiple Choice Question </Title>
          {
            (onNext || onPrevious) && showButton ? (
              <Flex justifyContent="space-between" style={{ gap: 12 }} alignItems="center">
                {
                  onPrevious && (
                    <Button onClick={onPrevious} size="s" noIcon>
                      Previous Question
                    </Button>
                  )
                }
                {
                  onNext && (
                    <Button onClick={onNext} size="s" noIcon>
                      Next Question
                    </Button>
                  )
                }
              </Flex>
            ) : null
          }
          <Flex justifyContent="flex-end">
            <DeleteButtonContainer onClick={onDelete}>
              {showButton && (
                <>
                  <FaTrash />
                  <DeleteButton>Delete</DeleteButton>
                </>
              )}
            </DeleteButtonContainer>
            <CancelButton
              onClick={onCancel}
              size="s"
              style={{ border: 'none' }}
            >
              Cancel
            </CancelButton>

            <SaveButton onClick={handleSave} size="s" noIcon>
              Save
            </SaveButton>
          </Flex>
        </TopContainer>
        <Flex mb={3} px="5%" py="35px">
          <Box flexGrow={1}>
            <ContentContainer>
              <InputGroup>
                <Label>Question</Label>
                <QuestionField rows={2} value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
                <div className="links flexStart">
                  <Link anchor href={ClassedUpMarkupGuide} name="markup-guide" target="_blank" rel="noreferrer">Markup guide</Link>
                </div>
              </InputGroup>
              {layoutOrientation === 'vert' && (
                <QuestionVerticalLayout
                  answers={answers}
                  answerIndicator={AnswerIndicator}
                  handleChangeAnswerText={handleChangeAnswerText}
                  orientation={layoutOrientation}
                  updateContent={updateContent}
                  removeContent={removeContent}
                  getContent={getContent}
                  contentDictionary={contentDictionary}
                />
              )}
              {layoutOrientation === 'horiz' && (
                <QuestionLayout
                  answers={answers}
                  answerIndicator={AnswerIndicator}
                  handleChangeAnswerText={handleChangeAnswerText}
                  orientation={layoutOrientation}
                  updateContent={updateContent}
                  removeContent={removeContent}
                  getContent={getContent}
                  contentDictionary={contentDictionary}
                />
              )}
            </ContentContainer>
          </Box>
          <ExtraOptionsSidebar>
            <ExtraOptions>
              <LabelLeft>Layout Display</LabelLeft>
              <Radio
                type="radio"
                name="layoutOrientation-vertical"
                value="vert"
                onChange={handleChangeLayoutOrientation}
                checked={layoutOrientation === 'vert'}
              />
              {' Vertical '}
              <Radio
                type="radio"
                name="layoutOrientation-horizontal"
                value="horiz"
                onChange={handleChangeLayoutOrientation}
                checked={layoutOrientation === 'horiz'}
              />
              {' Horizontal '}
              <LabelLeft>Multiple Correct Answer </LabelLeft>
              <Radio
                type="radio"
                name="allowMultipleCorrectAnswer"
                value={true}
                onChange={(e) => setAllowMultipleAnswers(e.target.checked)}
                checked={allowMultipleAnswers}
              />
              {'Yes '}
              <Radio
                type="radio"
                name="questionDifficulty"
                value="no"
                onChange={(e) => setAllowMultipleAnswers(!e.target.checked)}
                checked={!allowMultipleAnswers}
              />
              {'No'}
              <LabelLeft>Correct Answers </LabelLeft>
              <Choices>
                <Checkbox
                  type="checkbox"
                  onChange={(e) =>
                    handleChangeAnswerIsCorrect(
                      AnswerIndicator.A,
                      e.target.checked,
                    )
                  }
                  checked={answers[AnswerIndicator.A].isCorrect}
                  value={answers[AnswerIndicator.A].isCorrect}
                />
                {` A`}
              </Choices>
              <Choices>
                <Checkbox
                  type="checkbox"
                  onChange={(e) =>
                    handleChangeAnswerIsCorrect(
                      AnswerIndicator.B,
                      e.target.checked,
                    )
                  }
                  checked={answers[AnswerIndicator.B].isCorrect}
                  value={answers[AnswerIndicator.B].isCorrect}
                />
                {` B`}
              </Choices>
              <Choices>
                <Checkbox
                  type="checkbox"
                  onChange={(e) =>
                    handleChangeAnswerIsCorrect(
                      AnswerIndicator.C,
                      e.target.checked,
                    )
                  }
                  checked={answers[AnswerIndicator.C].isCorrect}
                  value={answers[AnswerIndicator.C].isCorrect}
                />
                {` C`}
              </Choices>
              <Choices>
                <Checkbox
                  type="checkbox"
                  onChange={(e) =>
                    handleChangeAnswerIsCorrect(
                      AnswerIndicator.D,
                      e.target.checked,
                    )
                  }
                  checked={answers[AnswerIndicator.D].isCorrect}
                  value={answers[AnswerIndicator.D].isCorrect}
                />
                {` D`}
              </Choices>
              <LabelLeft>Question Difficulty Level</LabelLeft>
              <Select
                id="difficulty"
                name="difficulty"
                value={difficulty}
                onChange={handleChangeDifficulty}
              >
                <option key="-" value="">
                  {'Level'}
                </option>
                {difficulties.map((difficultyOption) => (
                  <option key={difficultyOption} value={difficultyOption}>
                    {difficultyOption}
                  </option>
                ))}
              </Select>
            </ExtraOptions>
          </ExtraOptionsSidebar>
        </Flex>
      </Container>
    </Layout>
  );
};

/**
 * @param {Answer[]} initialAnswers
 * @param {Answer[]} defaultAnswers
 * @param {number} maxNumberOfAnswers
 * @returns {Answer[]}
 */
function getSafeAnswers(initialAnswers, defaultAnswers, maxNumberOfAnswers) {
  if (initialAnswers?.length > maxNumberOfAnswers) {
    // eslint-disable-next-line no-console
    console.warn(`Maximum number of answers exceeded, truncating answers`, {
      answers: initialAnswers,
    });
  }

  return [...(initialAnswers || []), ...defaultAnswers]
    .slice(0, maxNumberOfAnswers)
    .map(getSafeAnswer);
}

/**
 * @param {Answer} answer
 * @returns {Answer}
 */
function getSafeAnswer(answer) {
  let safeAnswer = {};
  if (typeof answer?.text === 'string') {
    safeAnswer.text = answer.text;
  } else {
    // eslint-disable-next-line no-console
    console.warn('Invalid answer:', { answer });
  }
  safeAnswer.isCorrect = Boolean(answer?.isCorrect);
  return safeAnswer;
}

/**
 * @param {Answer[]} answers the array of answers
 * @returns {boolean} true if has more than one correct answer
 */
function hasMoreThanOneCorrectAnswer(answers) {
  if (!Array.isArray(answers)) return 0;

  let count = 0;
  for (const answer of answers) {
    if (answer.isCorrect) {
      count++;
    }

    if (count > 1) {
      return true;
    }
  }

  return false;
}

/**
 * @param {Answer[]} answers
 */
function countCorrectAnswers(answers) {
  return answers.reduce((acc, answer) => {
    if (answer.isCorrect) {
      return acc + 1;
    }
    return acc;
  }, 0);
}

TestLibraryQuestionsMultipleChoice.propTypes = {
  difficulties: PropTypes.arrayOf(PropTypes.string),
  questionNumber: PropTypes.string,
};

export default TestLibraryQuestionsMultipleChoice;
