import React, { useEffect, useMemo, useState } from 'react';
import Layout from '../../../components/Layout';
import { Container, ContentContainer } from './styled';
import PackageLibraryNavbar from '../Navbar';
import TestLibraryQuestionGroupBuild from './Build';
import { InputField } from '../../UI';
import { useNavigate, useParams } from 'react-router-dom';
import routes from '../../../routes';
import { Loading } from '../../UI/Loaders';
import strings from '../../../constants/strings';
import {
  useCreateQuestionGroup,
  useDeleteQuestionGroup,
  useQuestionGroups,
  useUpdateQuestionGroup,
} from '../../../contexts/api/question-group';
import { useQuestions } from '../../../contexts/api/question';
import { useUpdateQuestionGroupOrder } from '../../../contexts/api/question-group/updateQuestionGroupOrder';
import { useSnackbar, useModal } from '../../../contexts/ui';

const TestLibraryQuestionGroup = (props) => {
  
  let { id } = useParams()
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const {
    questionGroups,
    getQuestionGroups,
    loading: loadingQuestionGroups,
    errorMessage: errorGettingQuestionGroups,
  } = useQuestionGroups();

  const {
    questions,
    getQuestions,
    loading: loadingQuestions,
    errorMessage: errorGettingQuestions,
  } = useQuestions();

  const {
    updateQuestionGroupOrder,
    loading: loadingUpdateQuestionGroupOrder,
    errorMessage: errorUpdatingQuestionGrouporder,
  } = useUpdateQuestionGroupOrder();

  const {
    createQuestionGroup,
    loading: createInProgress,
    errorMessage: errorCreatingQuestionGroup,
  } = useCreateQuestionGroup();
  const {
    updateQuestionGroup,
    loading: updateInProgress,
    errorMessage: errorUpdatingQuestionGroup,
  } = useUpdateQuestionGroup();
  const {
    deleteQuestionGroup,
    loading: deleteInProgress,
    errorMessage: errorDeletingQuestionGroup,
  } = useDeleteQuestionGroup();

  const loading =
    loadingQuestionGroups ||
    loadingUpdateQuestionGroupOrder ||
    loadingQuestions ||
    createInProgress ||
    updateInProgress ||
    deleteInProgress;

  const errorMessage =
    errorGettingQuestionGroups ||
    errorGettingQuestions ||
    errorUpdatingQuestionGrouporder ||
    errorCreatingQuestionGroup ||
    errorUpdatingQuestionGroup ||
    errorDeletingQuestionGroup;

  const questionGroupObject = useMemo(
    () => questionGroups.find((qg) => qg.id === id) || { name: '' },
    [id, questionGroups],
  );

  useEffect(() => {
    setName(questionGroupObject.name);
  }, [questionGroupObject.name]);

  useEffect(() => {
    getQuestions(id);
  }, [getQuestions, id]);

  useEffect(() => {
    if (errorMessage) {
      showSnackbar('errorMessage', 'error');
    }
  }, [errorMessage, showSnackbar]);

  const saveQuestionGroup = id
    ? () => updateQuestionGroup(id, { name })
    : () => createQuestionGroup({ name });

  const isValid = () => {
    return Boolean(name);
  };

  const handleSave = async () => {
    if (!isValid()) {
      showSnackbar('Name is required.', 'error');
      return;
    }

    const result = await saveQuestionGroup();
    getQuestionGroups();
    if (result.success) {
      showSnackbar(
        strings.en.testLibrary.addQuestionGroup.successMessage(name),
      );
      setId(result.questionGroup.id);
    } else {
      showSnackbar(
        strings.en.testLibrary.addQuestionGroup.errorMessage(name),
        'error',
      );
    }
  };

  const handleChangeOrder = async (questions) => {
    const newOrderOfQuestions = {
      questionNumbers: questions.map((q) => q.id),
    };
    const result = await updateQuestionGroupOrder(id, newOrderOfQuestions);
    await getQuestions(id);
    if (result.success) {
      showSnackbar(
        strings.en.testLibrary.updateQuestionGroupOrder.successMessage,
      );
    } else {
      showSnackbar(
        strings.en.testLibrary.updateQuestionGroupOrder.errorMessage,
        'error',
      );
    }
  };

  const handleDeleteConfirm = async () => {
    const result = await deleteQuestionGroup(id);
    await getQuestionGroups();
    if (result.success) {
      showSnackbar(strings.en.testLibrary.deleteQuestionGroup.successMessage);
      navigate(routes.client.testLibrary);
    } else {
      showSnackbar(
        strings.en.testLibrary.deleteQuestionGroup.errorMessage,
        'error',
      );
    }
  };

  const handleDelete = () => {
    openModal({
      title: 'Delete',
      content: strings.en.confirmModal.questionGroup.confirmDelete,
      action: handleDeleteConfirm,
      actionLabel: 'Delete',
      actionColor: 'red',
    });
  };

  return (
    <Layout noBodyPadding>
      <Container>
        <PackageLibraryNavbar
          onCancel={() => navigate(routes.client.testLibrary)}
          onSave={handleSave}
          onDelete={handleDelete}
          showDeleteButton={Boolean(id)}
        >
          <InputField
            id="create-question-title"
            placeholder="Insert the Question Group Name here"
            value={name || ''}
            onChange={(e) => setName(e.target.value)}
            inputConfig={{ autoFocus: true, value: name }}
          />
          {loading && <Loading style={{ paddingLeft: '10px' }} />}
        </PackageLibraryNavbar>
        <ContentContainer>
          <TestLibraryQuestionGroupBuild
            questionGroupId={id}
            existingQuestions={questions}
            onQuestionOrderChange={handleChangeOrder}
          />
        </ContentContainer>
      </Container>
    </Layout>
  );
};

export default TestLibraryQuestionGroup;
