import React, { useState } from 'react';
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Flex } from 'rebass';
import AnswerBox from './AnswerBox';
import { types as mediaTypes } from '../../../MediaLibrary/Media';
import { Link } from '../../../UI';
import { InputGroup, Label, TextAreaShortHorizontal } from './styled';
import ClassedUpMarkupGuide from '../../../../assets/pdf/ClassedUpMarkupGuide.pdf';
import { SUPPORTED_MEDIA_TYPES } from '.';
import ContentItem from './ContentItem';
import AddContentItemButton from './AddContentItemButton';
import MediaLibrarySelector from '../../../MediaLibrary/Selector';
import { assetContentTransformer } from '../../../../utils/mediaLibrary';

const QuestionVerticalLayout = ({ answers, answerIndicator, updateContent, removeContent, getContent, contentDictionary, handleChangeAnswerText }) => {
    const [mediaPicker, setMediaPicker] = useState({ open: false, answerIndex: null, showTypes: [] });
    const initialContentItems = useMemo(() => {
        const contentItems = [];
        const contentKeys = Object.keys(contentDictionary);
        contentKeys.forEach(key => {
            if(SUPPORTED_MEDIA_TYPES.includes(key)) {
                const id = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
                contentItems.push({ id, type: key });
            }
        });
        return contentItems;
    }, [contentDictionary]);

    const [contentItems, setContentItems] = useState(initialContentItems);
    const [contextText, setContextText] = useState(() => {
        const contentKeys = Object.keys(contentDictionary);
        let text = '';

        // find all unsupported content types and create a string of all the content
        const unsupportedContent = contentKeys.filter(key => !SUPPORTED_MEDIA_TYPES.includes(key));
        unsupportedContent.forEach(key => {
            if (key === 'text') {
                text += contentDictionary[key];
            } else {
                text += `\\${key}(${contentDictionary[key]})`;
            }
        });

        return text;
    }, [contentDictionary]);
    const AnswerLetters = Object.keys(answerIndicator);

    const handleContextTextChange = (e) => {
        const value = e.target.value;
        setContextText(value);

        // remove unsupported content types in dictionary
        const contentKeys = Object.keys(contentDictionary);
        const unsupportedContent = contentKeys.filter(key => !SUPPORTED_MEDIA_TYPES.includes(key));
        unsupportedContent.forEach(key => {
            if(contentDictionary[key]) delete contentDictionary[key];
        });

        // update dictionary with new content
        updateContent(value);
    };

    const openMediaPicker = (answerIndex = null) => {
        const showTypes = [mediaTypes.IMAGE, mediaTypes.AUDIO]
        if (!answerIndex) showTypes.push(mediaTypes.VIDEO)

        setMediaPicker({ ...mediaPicker, open: true, answerIndex, showTypes })
    }

    const closeMediaPicker = () => {
        setMediaPicker({ ...mediaPicker, open: false })
    }

    const insertAsset = (asset) => {
        handleChangeAnswerText(mediaPicker.answerIndex, assetContentTransformer(asset))
        closeMediaPicker()
    }
    
    // sort contentItems by type in order of phrase, audio, video, image
    contentItems.sort((a, b) => {
        if (a.type === mediaTypes.PHRASE) {
            return -1;
        } else if (b.type === mediaTypes.PHRASE) {
            return 1;
        }
        
        if (a.type === mediaTypes.AUDIO) {
            return -1;
        } else if (b.type === mediaTypes.AUDIO) {
            return 1;
        }

        if (a.type === mediaTypes.VIDEO) {
            return -1;
        } else if (b.type === mediaTypes.VIDEO) {
            return 1;
        }

        if (a.type === mediaTypes.IMAGE) {
            return -1;
        } else if (b.type === mediaTypes.IMAGE) {
            return 1;
        }

        return 0;
    });

    return (
        <Flex flexDirection="column" mx="auto" maxWidth="700px">
            <Box>
                <InputGroup>
                    <Label alignSelf="center" >Content</Label>
                    <TextAreaShortHorizontal
                        placeholder="Insert text here (including markups)."
                        value={contextText}
                        onChange={handleContextTextChange}
                    />
                    <div className="links flexStart">
                    <Link anchor to={ClassedUpMarkupGuide} name="markup-guide" target="_blank" rel="noreferrer">Markup guide</Link>
                    </div>
                </InputGroup>
            </Box>
            { 
                contentItems.length > 0 && contentItems.map((item) => {
                    const content = getContent(item.type);
                    return (
                        <ContentItem 
                            key={item.id}
                            itemId={item.id}
                            type={item.type}
                            setContentItems={setContentItems}
                            content={content}
                            updateContent={updateContent}
                            removeContent={removeContent}
                        />
                    )
                })
            }
            <AddContentItemButton 
                contentItems={contentItems} 
                setContentItems={setContentItems} 
                updateContent={updateContent}
                handleChangeAnswerText={handleChangeAnswerText} 
            />
            <Flex className='vert' flexDirection="column" maxWidth="700px" mx="auto" py={3}>
                <Label alignSelf="center" > Answers</Label>
                <Flex flexDirection="row" flexWrap="wrap" justifyContent="space-between">
                    {answers.map((answer, index) => (
                        <AnswerBox
                            key={index}
                            width={1 / 2} display="flex" flexDirection="column" px={10} py={3}
                            answerText={answer.text}
                            onChange={(e) => handleChangeAnswerText(index, e.target.value)}
                            onSelectAsset={() => openMediaPicker(index)}
                            onRemoveAsset={() => handleChangeAnswerText(index, '')}
                            letter={AnswerLetters[index]} />
                    ))}
                </Flex>
                {mediaPicker.open && <MediaLibrarySelector
                    onSelectMedia={(a) => insertAsset(a)}
                    onClose={closeMediaPicker}
                    showTypes={mediaPicker.showTypes}
                    fullscreen={true}
                />}
            </Flex>
        </Flex >
    )
}

QuestionVerticalLayout.propTypes = {
    answers: PropTypes.array.isRequired,
    answerIndicator: PropTypes.object.isRequired,
    updateContent: PropTypes.func.isRequired,
    removeContent: PropTypes.func.isRequired,
    getContent: PropTypes.func.isRequired,
    handleChangeAnswerText: PropTypes.func.isRequired,
    orientation: PropTypes.oneOf(['vert', 'horiz'])
}

export default QuestionVerticalLayout
