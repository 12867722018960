import Layout from '../../../Layout';
import React, { useEffect, useMemo, useState } from 'react';
import { CancelButton, SaveButton, Button } from '../../../UI/Button';
import { Link } from '../../../UI';
import { Flex, Box } from 'rebass';
import { types as mediaTypes } from '../../../MediaLibrary/Media';
import {
  Container,
  TopContainer,
  Title,
  ContentContainer,
  Label,
  InputGroup,
  QuestionField,
} from './styled';
import QuestionLayout from './QuestionLayout';
import { DeleteButton, DeleteButtonContainer } from '../../Navbar/styled';
import { FaTrash } from 'react-icons/fa';
import { QUESTION_CONTENT_TYPES } from '../../../../constants/application';
import ClassedUpMarkupGuide from '../../../../assets/pdf/ClassedUpMarkupGuide.pdf'
import { markupInContent } from '../../../../utils/markup';


export const SUPPORTED_MEDIA_TYPES = [mediaTypes.IMAGE, mediaTypes.AUDIO];

const TestLibraryQuestionsJumble = ({
  jumbleLetters: initialJumbleLetters = "",
  jumbleAnswer: initialJumbleAnswer = "",
  content: initialContent = '',
  loading = false,
  onCancel = () => { },
  onDelete = () => { },
  onSave = () => { },
  questionNumber,
  question: initialQuestion = '',
  showButton = false,
  type = QUESTION_CONTENT_TYPES.jumble,
  onNext,
  onPrevious
}) => {
  const [question, setQuestion] = useState(initialQuestion);
  useEffect(() => {
    setQuestion(initialQuestion);
  }, [initialQuestion]);

  /**
   * parse content string, if any markup exists, store it as a key value pair.
   * initialContent = \phrase(Le Chèvre)\audio(audio-uuid)\image(image-uuid) Hello World
   * dictionary = { phrase: 'Le Chèvre', audio: 'audio-uuid', image: 'image-uuid', text: 'Hello World' }
   */
  const initialContentDictionary = useMemo(() => {
    const dictionary = {};
    const regex = /\\(\w+)\(([^)]+)\)/gi;
    const found = initialContent.match(regex);

    if (found) {
      found.forEach((match) => {
        const [key, value] = match.split('(');
        dictionary[key.slice(1)] = value.slice(0, -1);
      });
    }

    return dictionary;
  }, [initialContent]);

  const [ contentDictionary, setContentDictionary ] = useState(initialContentDictionary);
  const [ jumbleLetters, setJumbleLetters ] = useState(initialJumbleLetters);
  const [ jumbleAnswer, setJumbleAnswer ] = useState(initialJumbleAnswer);

  const updateContent = (newContent) => {
    // Update the content in the dictionary if key exists, otherwise add it
    // The new content could be a markup or plain text or a combination of both
    if(newContent) {
      const markup = markupInContent(newContent);
      if(markup) {
        const { type, content } = markup;
        const key = type.toLowerCase();
        // remove the markup from the content string
        const leftoverContent = newContent.replace(markup.match, '');
        setContentDictionary((current) => ({
          ...current,
          [key]: content,
          'text': leftoverContent
        }));
      } else {
        setContentDictionary((current) => ({
          ...current,
          'text': newContent
        }));
      }
    }
  };

  const removeContent = (type) => {
    if(type) {
      const key = type.toLowerCase();
      // Remove the content from the dictionary if key exists
      if(contentDictionary[key]) {
        setContentDictionary((current) => {
          const newValues = {...current};
          delete newValues[key];
          return newValues;
        });
      }
    }
  };

  const getContent = (type) => {
    if(type) {
      const key = type.toLowerCase();
      // Get the content from the dictionary if key exists
      if(contentDictionary[key]) {
        return contentDictionary[key];
      }
    }
    return '';
  }

  const dictionaryToContent = (dictionary) => {
    return Object.keys(dictionary).map((key) => {
      // If the key is text, return the content as is
      if (key === 'text') return dictionary[key];
      return `\\${key}(${dictionary[key]})`;
    }).join('');
  };

  const handleSave = () => {
    const content = dictionaryToContent(contentDictionary);
    const payload = {
      question,
      content,
      questionNumber,
      jumbleLetters,
      jumbleAnswer,
      type,
    };

    onSave(payload);
  };

  return (
    <Layout noBodyPadding>
      <Container>
        <TopContainer>
          <Title> Jumble Question </Title>
          {
            (onNext || onPrevious) && showButton ? (
              <Flex justifyContent="space-between" style={{ gap: 12 }} alignItems="center">
                {
                  onPrevious && (
                    <Button onClick={onPrevious} size="s" noIcon>
                      Previous Question
                    </Button>
                  )
                }
                {
                  onNext && (
                    <Button onClick={onNext} size="s" noIcon>
                      Next Question
                    </Button>
                  )
                }
              </Flex>
            ) : null
          }
          <Flex justifyContent="flex-end">
            <DeleteButtonContainer onClick={onDelete}>
              {showButton && (
                <>
                  <FaTrash />
                  <DeleteButton>Delete</DeleteButton>
                </>
              )}
            </DeleteButtonContainer>
            <CancelButton
              onClick={onCancel}
              size="s"
              style={{ border: 'none' }}
            >
              Cancel
            </CancelButton>

            <SaveButton onClick={handleSave} size="s" noIcon>
              Save
            </SaveButton>
          </Flex>
        </TopContainer>
        <Flex mb={3} px="5%" py="35px">
          <Box flexGrow={1}>
            <ContentContainer>
              <InputGroup>
                <Label>Question</Label>
                <QuestionField rows={2} value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
                <div className="links flexStart">
                  <Link anchor href={ClassedUpMarkupGuide} name="markup-guide" target="_blank" rel="noreferrer">Markup guide</Link>
                </div>
              </InputGroup>
              <QuestionLayout
                updateContent={updateContent}
                removeContent={removeContent}
                getContent={getContent}
                contentDictionary={contentDictionary}
                jumbleLetters={jumbleLetters}
                jumbleAnswer={jumbleAnswer}
                setJumbleLetters={setJumbleLetters}
                setJumbleAnswer={setJumbleAnswer}
              />
            </ContentContainer>
          </Box>
        </Flex>
      </Container>
    </Layout>
  );
};

export default TestLibraryQuestionsJumble;
