import React from 'react';
import { useMemo } from 'react'
import { Box } from 'rebass';
import MediaElement from './MediaElement';
import { types as mediaTypes } from '../../../MediaLibrary/Media';
import { Link } from '../../../UI';
import { InputGroup, Label, MediaItemContainer, QuestionField } from './styled';
import { useGetAssets } from '../../../../contexts/api/asset';
import { capitalize } from 'lodash';

const ContentItem = ({ itemId, type, setContentItems, content, updateContent, removeContent }) => {
    const { assets, hasLoaded } = useGetAssets();

    const contentAsset = useMemo(() => {
        return assets.find(asset => asset.id === content);
    }, [assets, content])

    const handlePhraseChange = (e) => {
        const value = e.target.value;
        const newMarkup = `\\${type.toLowerCase()}(${value})`;
        updateContent(newMarkup);
    }

    const contentJSX = useMemo(() => {
        switch (type) {
            case mediaTypes.IMAGE:
                return contentAsset && (
                    <MediaItemContainer className={`content vert`}>
                        <MediaElement asset={contentAsset} />
                        <small>{contentAsset.name}</small>
                    </MediaItemContainer>
                )
            case mediaTypes.VIDEO:
                return contentAsset && (
                    <MediaItemContainer className={`content vert`}>
                        <MediaElement asset={contentAsset} />
                        <small>{contentAsset.name}</small>
                    </MediaItemContainer>
                )
            case mediaTypes.AUDIO:
                return contentAsset && (
                    <MediaItemContainer className={`content vert`}>
                        <MediaElement asset={contentAsset} />
                        <small>{contentAsset.name}</small>
                    </MediaItemContainer>
                )
            case mediaTypes.PHRASE:
                const val = content ? content : '';
                return (
                    <QuestionField
                        placeholder="Insert text here (including markups)."
                        value={val}
                        onChange={handlePhraseChange}
                    />
                )
            default:
                return null;
        }
    }, [contentAsset, content, type]);

    const handleRemoveItem = () => {
        setContentItems(prevItems => prevItems.filter(item => item.id !== itemId));
        removeContent(type);
    }

    return hasLoaded && (
        <Box minWidth='460px' maxWidth='460px' mx='auto'>
            <InputGroup>
                <Label alignSelf="center" >{capitalize(type)}</Label>
                { contentJSX }
                <div className="links flexStart">
                    <Link to="#" onClick={handleRemoveItem}>Remove Item</Link>
                </div>
            </InputGroup>
        </Box>
    )
}

export default ContentItem;