import React, { useState } from 'react';
import { string, func, arrayOf } from 'prop-types';
import { getFilteredMedia, tabs } from '../common';
import {
  Container,
  Title,
  TitleContainer,
  TabsContainer,
  IconButton,
  ButtonContainer,
  FullScreenContainer,
} from './styled';
import Tabs from '../../UI/Tabs';
import CloseIcon from '@material-ui/icons/Close';
import { Loading } from '../../UI/Loaders';
import MediaLibraryContent from '../Content';
import { MediaContainer } from '../styled';
import { Button } from '../../UI';
import { useGetAssets } from '../../../contexts/api/asset';

const MediaLibrarySelector = ({
  onSelectMedia,
  onClose,
  showTypes,
  fullscreen = false,
  ...rest
}) => {
  const { assets, loading } = useGetAssets();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedMedia, setSelectedMedia] = useState({});

  const getTabs = () => {
    if (!showTypes.length) {
      return tabs;
    }

    return tabs.filter((tab) => showTypes.includes(tab.type));
  };

  const contentJSX = (
    <>
      <TitleContainer>
        <Title>Media Library</Title>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </TitleContainer>
      <TabsContainer>
        <Tabs
          tabTextColor="#424242"
          tabs={getTabs().map((tab) => tab.label)}
          onSelectTab={setSelectedTabIndex}
          selectedTabIndex={selectedTabIndex}
        />
      </TabsContainer>
      <MediaContainer style={{ maxWidth: "100%" }}>
        {loading ? (
          <Loading />
        ) : (
          <MediaLibraryContent
            media={getFilteredMedia(assets, getTabs()[selectedTabIndex]?.type)}
            onClickMedia={setSelectedMedia}
            isSelected={(m) => selectedMedia.id === m.id}
          />
        )}
      </MediaContainer>
      <ButtonContainer>
        <Button
          width={125}
          disabled={!selectedMedia.id}
          onClick={() => onSelectMedia(selectedMedia)}
        >
          OK
        </Button>
      </ButtonContainer>
    </>
  )

  return fullscreen ? (
    <FullScreenContainer>
      {contentJSX}
    </FullScreenContainer>
  ) : (
    <Container {...rest}>
      {contentJSX}
    </Container>
  );
};

MediaLibrarySelector.propTypes = {
  onSelectMedia: func.isRequired,
  onClose: func.isRequired,
  showTypes: arrayOf(string),
};

MediaLibrarySelector.defaultProps = {
  showTypes: [],
};

export default MediaLibrarySelector;
