import { types as assetTypes } from "../components/MediaLibrary/Media"

/**
 * Return any markup in the content 
 * ex: \image(ID)
 * @param {String} content - string that might include any available markups
 * @param {String} type - filter to only return a specific type of markup
 * @returns {{match: String, type: String, content: String} | null} 
 * 
 */
export const markupInContent = (content, type) => {
    if (!content) return null;
    const assetMarkups = Object.values(assetTypes);
    const markups = assetMarkups.filter(markup => !type || markup === type.toLowerCase());
    const regex = new RegExp(`\\\\(${markups.join('|')})\\(([^)]+)\\)`);
    const found = content.match(regex);
    return found ? { match: found[0], type: found[1], content: found[2] } : null;
}

/**
 * Return markups in the content in a dictionary. Only the markups specified in [type] are
 * return in their own key/value pair- all other markups are grouped in "text" key.
 * @param {String} content - string that might include any available markups
 * @param {String} [type] - filter to only return a specific types of markups, the rest is returned in "text" key
 * @returns {{match: String, type: String, content: String} | null} 
 * 
 */
export const markupsInContent = (content, types) => {
    const dictionary = {};
    var foundInitialSlash = false;
    var openParenCount = 0;
    var assembledKey = "";
    var assembledValue = "";
    var assembledText = "";   // the leftovers that aren't part of a markup is just plain text. currently, just supports one "text"
    for (let char of content) {
      if (foundInitialSlash && openParenCount == 0) {
        if (char == '(') {
          openParenCount = 1;
        } else {
          assembledKey = assembledKey + char;
        }
      } else if (openParenCount > 0) {
        if (char == ')')  {
          openParenCount = openParenCount - 1;
          if (openParenCount == 0) {
            if (types && types.includes(assembledKey)) {
              // add these markups to the returned dictionary
              dictionary[assembledKey] = assembledValue;
            } else {
              // this markup stays in text
              assembledText = assembledText + "\\" + assembledKey + "(" + assembledValue + ")";
            }
            // reset for the next one
            foundInitialSlash = false;
            assembledKey = "";
            assembledValue = "";
            openParenCount = 0;
          } else {
            assembledValue = assembledValue + char;
          }
        } else {
          assembledValue = assembledValue + char;
          if (char == '(') {
            openParenCount = openParenCount + 1;
          }
        }
      } else if ((char == '\\') && (openParenCount == 0)) {
        foundInitialSlash = true;
      } else if (char == '(') {
        openParenCount = openParenCount + 1;
      } else {
        assembledText = assembledText + char;
      }
    }
    if (assembledText.length > 0) {
      dictionary["text"] = assembledText;
    }
    return dictionary;
}